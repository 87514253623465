import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../components/filter/CustomDatePicker';
import APPCONSTANTS from '../../constants/appConstants';
import DetailCard from '../../components/detailCard/DetailCard';
import CustomTable from '../../components/customtable/CustomTable';
import Loader from '../../components/loader/Loader';
import styles from './ReportList.module.scss';
import Modal from '../../components/modal/ModalForm';
import SpreadsheetViewer from '../../components/spreadSheetViewer/SpreadSheetViewer';
import { fetchReportList, fetchMOHReport, fileDownload, sendMOHReportToKHIS } from '../../services/reportAPI';
import { firstNameSelector, lastNameSelector, roleSelector } from '../../store/user/selectors';
import toastCenter from '../../utils/toastCenter';
import 'react-datepicker/dist/react-datepicker.css';
import SendIcon from '../../assets/images/send.svg';
import SendDisabledIcon from '../../assets/images/send-disabled.svg';
import PendingIcon from '../../assets/images/Pending.svg';
import SuccessIcon from '../../assets/images/success-blue.svg';
import ErrorIcon from '../../assets/images/Failed.svg';
import { Field, Form } from 'react-final-form';
import { composeValidators, required } from '../../utils/validation';
import TextInput from '../../components/formFields/TextInput';
import ERRORS from '../../constants/error';
import { useSelector } from 'react-redux';
import {
  getIsUserFacilityReportAdmin,
  getPageLocationWithParams,
  isKHISLastDateExceeded,
  trackGoogleAnalyticsEvent
} from '../../utils/commonUtils';

interface IMatchParams {
  siteId: string;
  siteName: string;
}

interface IDatesParams {
  id?: number;
  year?: string;
  month?: string;
  label?: string;
  khisStatus: any;
  khisMessage: any;
}

interface IReportLists {
  khisStatus: any;
  khisMessage: any;
  id: number;
  key: string;
}

const MOHReport = (props: IMatchParams): React.ReactElement => {
  const { siteId, siteName } = useParams<IMatchParams>();
  const startYearPickerRef = useRef<DatePicker>(null);
  const startMonthPickerRef = useRef<DatePicker>(null);
  const [modalParams, setModalReqParams] = useState({
    loading: false,
    data: {}
  });
  const [loading, setLoading] = useState(false);
  const [reportParams, setReportParams] = useState<IDatesParams[]>([]);
  const [startYear, setStartYear] = useState<null | Date>(null);
  const [startMonth, setStartMonth] = useState<null | Date>(null);
  const [showLoginModal, setShowLoginModal] = useState({
    data: {},
    show: false
  });
  const [showAlertModal, setShowAlertModal] = useState({
    status: '',
    message: '',
    show: false
  });
  const [authenticationError, setAuthenticationError] = useState(false);
  const [isSendToKHISEnabled, setIsSendToKHISEnabled] = useState(false);
  const [isSendToKHISAuthorized, setIsSendToKHISAuthorized] = useState(true);
  const role: any = useSelector(roleSelector);
  const firstName: string = useSelector(firstNameSelector) || '';
  const lastName: string = useSelector(lastNameSelector) || '';
  let selectedYear: string;
  let selectedMonth: string;

  useEffect(() => {
    trackGoogleAnalyticsEvent(
      APPCONSTANTS.USER_ACTIONS.LIST_VIEW,
      APPCONSTANTS.REPORT_LIST.MOH_REPORT,
      getPageLocationWithParams(),
      { username: `${firstName} ${lastName}`, role: role.label }
    );
    getReportList('');
    getIsSendToKHISEnabled();
    setIsSendToKHISAuthorized(getIsUserFacilityReportAdmin(role));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!startYear) {
      startMonthPickerRef.current?.props.onChange(null, undefined);
      setStartMonth(null);
    }
    if ((startYear && !startMonth) || (startYear && startMonth)) {
      startMonthPickerRef.current?.props.minDate?.setDate(startYear.getDate());
      startMonthPickerRef.current?.props.minDate?.setMonth(startYear.getMonth());
      startMonthPickerRef.current?.props.minDate?.setFullYear(startYear.getFullYear());
    }
    if (startMonth) {
      startYearPickerRef.current?.props.maxDate?.setDate(startMonth.getDate());
      startYearPickerRef.current?.props.maxDate?.setMonth(startMonth.getMonth());
      startYearPickerRef.current?.props.maxDate?.setFullYear(startMonth.getFullYear());
    }
  }, [startYear, startMonth]);

  const getReportList = useCallback(
    async (yearMonth: string) => {
      const datesList: IDatesParams[] = [];
      try {
        setLoading(true);
        const payLoad = await fetchReportList({
          key: yearMonth,
          reportType: 'MOH-740',
          healthFacilityId: Number(siteId),
          limit: APPCONSTANTS.NUMBER.TWELVE
        });
        payLoad.data.entityList.map((res: IReportLists) => {
          return datesList.push({
            id: res.id,
            year: res.key.slice(3, 7),
            month: formatMonthName(res.key.slice(0, 3)),
            label: res.key.slice(0, 3),
            khisStatus: res.khisStatus,
            khisMessage: res.khisMessage
          });
        });
        setLoading(false);
        setReportParams(datesList);
      } catch (error: any) {
        setReportParams(datesList);
        setLoading(false);
        toastCenter.error(APPCONSTANTS.ERROR, APPCONSTANTS.REPORT_NOT_FOUND);
      }
    },
    [siteId]
  );

  const openKHISAuthentication = useCallback(async (report) => {
    setShowLoginModal({ data: report, show: true });
  }, []);

  const constructKHISPayload = useCallback(
    (rawData: any) => {
      rawData.healthFacilityId = Number(siteId);
      rawData.month = rawData.label;
      rawData.year = Number(rawData.year);
      delete rawData.label;
      delete rawData.id;
      return rawData;
    },
    [siteId]
  );

  const getIsSendToKHISEnabled = () => {
    isKHISLastDateExceeded() ? setIsSendToKHISEnabled(false) : setIsSendToKHISEnabled(true);
  };

  const sendToKHIS = useCallback(
    async (rawKHISData) => {
      try {
        const KHISPayload = constructKHISPayload(rawKHISData);
        setLoading(true);
        const { data } = await sendMOHReportToKHIS(KHISPayload);
        setShowLoginModal({ data: {}, show: false });
        setLoading(false);
        setAuthenticationError(false);
        setShowAlertModal({
          status: APPCONSTANTS.SUCCESS,
          message: data.message,
          show: true
        });
      } catch (error: any) {
        setLoading(false);
        if (error.message === ERRORS.KHIS_NOT_AUTHENTICATED.message) {
          setAuthenticationError(true);
        } else {
          setShowLoginModal({ data: {}, show: false });
          setShowAlertModal({ status: APPCONSTANTS.ERROR, message: error.message, show: true });
        }
      }
    },
    [constructKHISPayload]
  );

  const formatMonthName = (month: string) => {
    const result = APPCONSTANTS.MONTH.find((item) =>
      Object.entries(item).some(([key, value]) => key === 'label' && value === month)
    );
    return result?.name;
  };

  const isLastMonth = (report: any) => {
    const current = new Date();
    const thisYear = current.getFullYear();
    current.setMonth(current.getMonth() - 1);
    const lastMonth = APPCONSTANTS.MONTH[current.getMonth()].label;
    return (
      isSendToKHISEnabled &&
      ((report.label === 'Dec' && lastMonth === report.label && Number(report.year) === thisYear - 1) ||
        (lastMonth === report.label && Number(report.year) === thisYear))
    );
  };

  const getReportView = useCallback(
    async (report) => {
      try {
        setLoading(true);
        const {
          data: { entityList }
        } = await fetchMOHReport({
          month: report.label,
          year: report.year,
          healthFacilityId: Number(siteId)
        });
        trackGoogleAnalyticsEvent(
          APPCONSTANTS.USER_ACTIONS.REPORT_VIEW,
          APPCONSTANTS.REPORT_LIST.MOH_REPORT,
          getPageLocationWithParams(),
          { username: `${firstName} ${lastName}`, role: role.label }
        );
        setLoading(false);
        setModalReqParams({ loading: true, data: entityList });
      } catch (error: any) {
        setLoading(false);
        toastCenter.error(APPCONSTANTS.ERROR, APPCONSTANTS.REPORT_NOT_FOUND_MONTH);
      }
    },
    [firstName, lastName, role.label, siteId]
  );

  const downloadFile = useCallback(
    async (report) => {
      try {
        setLoading(true);
        const {
          data: { entityList }
        } = await fetchMOHReport({
          month: report.label,
          year: report.year,
          healthFacilityId: Number(siteId)
        });
        trackGoogleAnalyticsEvent(
          APPCONSTANTS.USER_ACTIONS.REPORT_DOWNLOAD,
          APPCONSTANTS.REPORT_LIST.MOH_REPORT,
          getPageLocationWithParams(),
          { username: `${firstName} ${lastName}`, role: role.label }
        );
        setLoading(false);
        fileDownload(siteName, '_MOH_', report.year + '_' + report.month, entityList);
        toastCenter.success(APPCONSTANTS.SUCCESS, APPCONSTANTS.REPORT_DOWNLOAD_SUCCESS);
      } catch (error: any) {
        setLoading(false);
        toastCenter.error(APPCONSTANTS.ERROR, APPCONSTANTS.REPORT_NOT_FOUND_MONTH);
      }
    },
    [siteId, firstName, lastName, role.label, siteName]
  );

  const viewModalRender = () => {
    return (
      <SpreadsheetViewer
        data={modalParams.data}
        isEdit={false}
        className={styles.spreadSheetContainer}
        colWidth={150}
        reportName={APPCONSTANTS.REPORT_LIST.MOH_REPORT}
        reportSize={{ charPixel: 10, index: [], rowHeight: 25 }}
      />
    );
  };

  const alertModalRender = () => {
    return (
      <div className={styles.alertItems}>
        <img
          className={styles.alertModalIcon}
          src={showAlertModal.status === APPCONSTANTS.SUCCESS ? SuccessIcon : ErrorIcon}
          alt='Success'
        />
        <p className={styles.alertModalText}>{showAlertModal.message}</p>
        <button
          className={styles.closeButton}
          onClick={() => {
            getReportList('');
            setShowAlertModal({
              status: '',
              message: '',
              show: false
            });
          }}
        >
          Done
        </button>
      </div>
    );
  };

  const loginModalRender = () => {
    return (
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <>
            <Field
              name='email'
              type='text'
              validate={composeValidators(required)}
              render={({ input, meta }) => (
                <TextInput
                  {...input}
                  label='Username'
                  errorLabel='email'
                  error={(meta.touched && meta.error) || undefined}
                />
              )}
            />
            <div className={styles.togglePassword}>
              <Field
                name='password'
                type={'password'}
                validate={required}
                render={({ input, meta }) => (
                  <TextInput
                    {...input}
                    label='Password'
                    errorLabel='password'
                    error={(meta.touched && meta.error) || undefined}
                    className={styles.passwordBox}
                  />
                )}
              />
            </div>
            {authenticationError && <p className={styles.errorText}>{ERRORS.KHIS_NOT_AUTHENTICATED.message}</p>}
            <button onClick={handleSubmit} type='submit' className='mt-2 btn primary-btn w-100'>
              Login and Send to KHIS
            </button>
          </>
        )}
      />
    );
  };

  const handleCancelClick = () => {
    setModalReqParams({ loading: false, data: {} });
  };

  const onYearSelection = (date: Date) => {
    if (date) {
      trackGoogleAnalyticsEvent(
        APPCONSTANTS.USER_ACTIONS.FILTERS.YEAR_FILTER,
        APPCONSTANTS.REPORT_LIST.MOH_REPORT,
        getPageLocationWithParams(),
        { username: `${firstName} ${lastName}`, role: role.label }
      );
      setStartYear(date);
      selectedYear = date.getFullYear().toString();
      if (startMonth) {
        selectedMonth = APPCONSTANTS.MONTH[startMonth.getMonth()].label;
        getReportList(selectedMonth + selectedYear);
      } else {
        getReportList(selectedYear);
      }
    } else {
      setStartYear(null);
      setStartMonth(null);
      getReportList('');
    }
  };

  const handleSendReport = (e: any, data: any) => {
    openKHISAuthentication(data);
  };

  const onSubmit = ({ email, password }: { email: string; password: string }) => {
    sendToKHIS({
      ...showLoginModal.data,
      username: email,
      password
    });
  };

  const onMonthSelection = (date: Date) => {
    if (date) {
      trackGoogleAnalyticsEvent(
        APPCONSTANTS.USER_ACTIONS.FILTERS.MONTH_FILTER,
        APPCONSTANTS.REPORT_LIST.MOH_REPORT,
        getPageLocationWithParams(),
        { username: `${firstName} ${lastName}`, role: role.label }
      );
      setStartMonth(date);
      selectedMonth = APPCONSTANTS.MONTH[date.getMonth()].label;
      if (startYear) {
        selectedYear = startYear.getFullYear().toString();
        getReportList(selectedMonth + selectedYear);
      }
    } else {
      setStartMonth(null);
      if (startYear) {
        selectedYear = startYear.getFullYear().toString();
        getReportList(selectedYear);
      }
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className='row'>
        <div className='col-auto'>
          <div className={`${styles.sideMenu} ${styles.customSidemenu}`}>
            <div className={`card-header bg-transparent ${styles.header}`}>
              <span className={styles.headerLabel}>Filters</span>
            </div>
            <br />
            <div>
              <label>Year</label>
              <CustomDatePicker
                pickerRef={startYearPickerRef}
                dayReducer={1}
                onDateSelect={(date: Date) => onYearSelection(date)}
                showYearPicker={true}
              />
              <br />
              <br />
              <div className={startYear ? '' : `${styles.disable}`}>
                <label>Month</label>
                <CustomDatePicker
                  pickerRef={startMonthPickerRef}
                  onDateSelect={(date: Date) => onMonthSelection(date)}
                  showMonthYearPicker={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='col'>
          <DetailCard header='Monthly Summary Form MOH 740'>
            <CustomTable
              rowData={reportParams}
              columnsDef={[
                {
                  id: 1,
                  name: 'year',
                  label: 'Year',
                  class: 'numeric'
                },
                {
                  id: 2,
                  name: 'month',
                  label: 'Month'
                }
              ]}
              isDelete={false}
              isEdit={false}
              isView={true}
              isDownload={true}
              onRowView={(report) => getReportView(report)}
              onRowDownload={(report) => downloadFile(report)}
              isCustom={isSendToKHISAuthorized}
              isStatus={isSendToKHISAuthorized}
              customProps={[
                {
                  title: (report: any) =>
                    isSendToKHISEnabled && isLastMonth(report)
                      ? APPCONSTANTS.SEND_TO_KHIS
                      : APPCONSTANTS.CANNOT_SEND_TO_KHIS,
                  icon: (report: any) => (isSendToKHISEnabled && isLastMonth(report) ? SendIcon : SendDisabledIcon),
                  style: (report: any) =>
                    isSendToKHISEnabled && isLastMonth(report) ? { width: 16 } : { cursor: 'no-drop', width: 16 },
                  onClick: (e: any, report: any) =>
                    isSendToKHISEnabled && isLastMonth(report) ? handleSendReport(e, report) : undefined,
                  isSendButtonEnabled: (report: any) => isLastMonth(report),
                  isMOHReport: true
                  // onClick: isSendToKHISEnabled ? undefined : undefined
                }
              ]}
              customStatusProps={[
                {
                  title: APPCONSTANTS.REPORT_PENDING,
                  icon: PendingIcon,
                  style: { width: 16 },
                  tooltipFormatter: (report: any) => report?.khisMessage,
                  iconFormatter: (report: any) => report?.khisStatus
                }
              ]}
            />
          </DetailCard>
          <Modal
            show={modalParams.loading}
            title='Monthly Summary Form MOH 740 View'
            size='modal-full'
            hasFooter={false}
            handleCancel={handleCancelClick}
            handleFormSubmit={handleCancelClick}
            render={viewModalRender}
          />
          <Modal
            show={showLoginModal.show}
            title='Send Report to KHIS'
            size='modal-md'
            autoHeight={true}
            hasFooter={false}
            handleCancel={() => {
              setAuthenticationError(false);
              setShowLoginModal({ data: {}, show: false });
            }}
            // tslint:disable-next-line:no-empty
            handleFormSubmit={() => {}}
            render={loginModalRender}
          />
          <Modal
            show={showAlertModal.show}
            isAlertModal={true}
            title=''
            size='modal-md'
            autoHeight={true}
            hasFooter={false}
            // tslint:disable-next-line:no-empty
            handleFormSubmit={() => {}}
            render={alertModalRender}
          />
        </div>
      </div>
    </>
  );
};

export default MOHReport;
