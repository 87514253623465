import axios from 'axios';
import { saveAs } from 'file-saver';
import {
  IAssessmentListRequestPayload,
  IEnrolledPatientsReportPayload,
  IScreenedReportPayload
} from '../store/report/types';

export const fileDownload = async (siteName: string, type: string, reportDate: any, reportData: []) => {
  const fileName = siteName + type + reportDate;
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const blob = new Blob([new Uint8Array(reportData)], { type: fileType });
  return saveAs(blob, fileName + fileExtension, { autoBom: false });
};

export const fetchReportList = (data: {
  key: string;
  reportType: string;
  healthFacilityId: number;
  limit: number;
  userBased?: boolean;
  userId?: number | undefined;
}) =>
  axios({
    method: 'POST',
    url: 'cfr-service/report/list',
    data
  });

export const fetchHFReport = (data: {
  month: string;
  year: string;
  healthFacilityId: number | undefined;
  userId?: number;
  userBased: boolean;
  reportType: string;
}) =>
  axios({
    method: 'POST',
    url: 'cfr-service/report/hf-report',
    data
  });

export const isMOHReportPending = (data: { key: string; report_type: string; facilityId: string }) =>
  axios({
    method: 'POST',
    url: 'spice-connect/reports/status',
    data
  });

export const fetchDailyReport = (data: { date: string; healthFacilityId: number }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/reporting-analytics/daily-register',
    data
  });

export const downloadAssessmentListReport = (data: { siteName: string }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/reporting-analytics/assessed-patients-download',
    data,
    responseType: 'blob'
  }).then((response) => {
    const filename = data.siteName.replace(/\./g, ' ') + '_Assessment_Report';
    saveAs(
      new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
      filename,
      { autoBom: false }
    );
  });

export const downloadEnrolledPatientsReport = (data: { siteName: string }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/reporting-analytics/enrolled-patients-download',
    data,
    responseType: 'blob'
  }).then((response) => {
    const filename = data.siteName.replace(/\./g, ' ') + '_Enrolled_Patients';
    saveAs(
      new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
      filename,
      { autoBom: false }
    );
  });

export const fetchMOHReport = (data: { month: string; year: string; healthFacilityId: number }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/reporting-analytics/MOH',
    data
  });

export const sendMOHReportToKHIS = (data: {
  month: string;
  year: number;
  healthFacilityId: number;
  username: string;
  password: string;
}) =>
  axios({
    method: 'POST',
    url: 'spice-connect/reports/send-to-KHIS',
    data
  });

export const fetchPatientStatusReport = (data: { month: string; year: string; healthFacilityId: number }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/reporting-analytics/patient-status-report',
    data
  });

export const fetchPermanentReport = (data: { month: string; year: string; healthFacilityId: number }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/reporting-analytics/permanent-register',
    data
  });

export const fetchPrescriptionList = (data: {
  healthFacilityId: number;
  year?: number;
  month?: number;
  limit: number;
}) =>
  axios({
    method: 'POST',
    url: 'cfr-service/reporting-analytics/prescription-report-list',
    data
  });

export const fetchPrescriptionReport = (data: { healthFacilityId: number; fromDate: string; toDate: string }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/reporting-analytics/prescription-list',
    data
  });

export const downloadScreenedPatientsReport = (data: { siteName: string }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/reporting-analytics/screened-patients-download',
    data,
    responseType: 'blob'
  }).then((response) => {
    const filename = data.siteName.replace(/\./g, ' ') + '_Screened_Patients';
    saveAs(
      new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
      filename,
      { autoBom: false }
    );
  });

export const downloadUserPerformanceReport = (data: { siteName: string }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/reporting-analytics/user-performance-download',
    data,
    responseType: 'blob'
  }).then((response) => {
    const filename = data.siteName.replace(/\./g, ' ') + '_User_Performance';
    saveAs(
      new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
      filename,
      { autoBom: false }
    );
  });

export const fetchScreenedReport = (data: IScreenedReportPayload) =>
  axios({
    method: 'POST',
    url: 'cfr-service/reporting-analytics/screened-patient-list',
    data
  });

export const fetchEnrolledPatientReport = (data: IEnrolledPatientsReportPayload) =>
  axios({
    method: 'POST',
    url: 'cfr-service/reporting-analytics/enrolled-patient-list',
    data
  });

export const fetchAssessmentListReport = (data: IAssessmentListRequestPayload) =>
  axios({
    method: 'POST',
    url: 'cfr-service/reporting-analytics/assessed-patient-list',
    data
  });

export const fetchUserPerformanceReport = (data: {
  healthFacilityId: number;
  roles?: string[];
  fromDate?: string;
  toDate?: string;
  skip?: number;
  limit: number | null;
}) =>
  axios({
    method: 'POST',
    url: 'cfr-service/reporting-analytics/user-performance-report',
    data
  });
