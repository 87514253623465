const APPCONSTANTS = {
  HASH_ALGORITM: 'sha512',
  AUTHTOKEN: 'at',
  USERNAME: 'un',
  PASSWORD: 'pw',
  REMEMBER_ME: 'rm',
  TOTAL_COUNT: 'tc',
  IS_UNSURE_RECORD: 'iur',
  TENANT_ID: 'ti',
  USER_TENANTID: 'uti',
  COUNTRY_TENANT_ID: 'cti',
  ENCRYPTION: {
    ALGM: 'aes-256-gcm',
    IV: 'ncd@123',
    SALT: 'ncd@123',
    ITERATION: 2048,
    KEYLEN: 32,
    DIGEST: 'sha512'
  },
  ROWS_PER_PAGE_OF_TABLE: 10,
  ROLES: {
    REPORT_ADMIN: 'REPORT_ADMIN',
    FACILITY_REPORT_ADMIN: 'FACILITY_REPORT_ADMIN',
    HF4_REPORT_USER: 'HF4_REPORT_USER',
    PEER_SUPERVISOR: 'PEER_SUPERVISOR',
    REPORT_SUPER_ADMIN: 'REPORT_SUPER_ADMIN'
  },
  APPLICATION_NAME: {
    COUNTRY: { NAME: 'country', LABEl: 'Region' },
    ACCOUNT: { NAME: 'account', LABEL: 'Account' },
    OPERATING_UNIT: { NAME: 'operatingunit', LABEL: 'Operating unit' },
    SITE: { NAME: 'site', LABEL: 'Site' }
  },
  MONTH: [
    { name: 'January', label: 'Jan' },
    { name: 'February', label: 'Feb' },
    { name: 'March', label: 'Mar' },
    { name: 'April', label: 'Apr' },
    { name: 'May', label: 'May' },
    { name: 'June', label: 'Jun' },
    { name: 'July', label: 'Jul' },
    { name: 'August', label: 'Aug' },
    { name: 'September', label: 'Sep' },
    { name: 'October', label: 'Oct' },
    { name: 'November', label: 'Nov' },
    { name: 'December', label: 'Dec' }
  ],
  PAGE_LIST: {
    REPORT_LIST: 'Report List'
  },
  REPORT_LIST: {
    HF1_REPORT: 'HF1',
    HF2_REPORT: 'HF2',
    HF3_REPORT: 'HF3',
    HF4_REPORT: 'HF4',
    SPICE_QUICKSIGHT_DASHBOARD: 'Spice Quicksight Dashboard',
    SPICE_ENGAGE_QUICKSIGHT_DASHBOARD: 'Spice Engage Quicksight Dashboard',
    DAILY_REGISTER: 'Daily Register',
    PERMANENT_REGISTER: 'Permanent Register',
    MOH_REPORT: 'MOH-740 Report',
    PATIENT_STATUS_REPORT: 'Patient Status Report',
    PRESCRIPTION_MEDICATION: 'Prescription Medication Report',
    SCREENED_PATIENTS: 'Screened Patients Report',
    ENROLLED_PATIENTS: 'Enrolled Patients Report',
    ASSESSMENT_REPORT: 'Assesment Report',
    USER_PERFORMANCE_REPORT: 'User Performance Report'
  },
  REPORT_LIST_LABEL: {
    MONTHLY_SUMMARY: 'Monthly Summary Form MOH-740',
    PERMANANT_REGISTER: 'Permanent Register MOH-270',
    PATIENT_STATUS_REPORT: 'Patient Status Report',
    DAILY_ACTIVITY_REGISTER: 'Daily Activity Register MOH-222',
    SCREENED_PATIENTS: 'Screened Patients',
    ENROLLED_PATIENTS: 'Enrolled Patients',
    ASSESSMENT_REPORT: 'Assessment Report',
    PRESCRIPTION_MEDICATION: 'Prescription Medication',
    USER_PERFORMANCE: 'User Performance'
  },
  USER_ACTIONS: {
    LIST_VIEW: 'Report List Viewed',
    REPORT_VIEW: 'Report Details Viewed',
    REPORT_DOWNLOAD: 'Report Downloaded',
    QUICKSIGHT_DASHBOARD: 'Quicksight Dashboard Viewed',
    FILTERS: {
      DATE_FILTER: 'Date Filter used',
      MONTH_FILTER: 'Month Filter used',
      YEAR_FILTER: 'Year Filter used',
      GENDER_FILTER: 'Gender Filter used',
      DIAGNOSIS_FILTER: 'Diagnosis Filter used',
      REFER_ASSESSMENT_FILTER: 'Referred Assessment Filter used',
      ROLE_FILTER: 'Role Filter user'
    },
    DUPLICATE_PROFILE_ACTIONS: {
      MERGED_DUPLICATE_PROFILES: 'Merged Duplicate Profile',
      DUPLICATE_DIFFERENT: 'Duplicate Profile marked different'
    }
  },
  SUITE_ACCESS: {
    ADMIN: 'admin',
    CFR: 'cfr',
    INSIGHTS: 'insights'
  },
  ALERT: 'Alert',
  SUCCESS: 'Success',
  WARNING: 'Warning',
  OOPS: 'Oops',
  ERROR: 'Error',
  NETWORK_ERROR: 'Network Error',
  CONNECTION_LOST: 'There is an issue with the connection. Please try after sometime.',
  SESSION_EXPIRED: 'Session got expired. Please login again.',
  IS_SIDEMENU_COLLAPSED: 'isSmCollapsed',
  NO_RECORDS_FOUND: 'No records found',
  PAGINATION_RANGE: 5,
  FORM_ID: 'formId',
  REGION_ID: 'id',
  COUNTRY_CODE: 'cc',
  COUNTRY_NAME: 'countryName',
  REGION_NAME: 'regionName',
  IS_KENYA: 'ik',
  REGIONS_PER_PAGE: 10,
  REGION_FETCH_ERROR: 'Unable to load regions. Please try after sometime.',
  SITE_FETCH_ERROR: 'Unable to load sites. Please try after sometime.',
  USER_ROLES_LIST_FETCH_ERROR: 'Unable to load user roles list. Please try after sometime.',
  SITE_ADMIN_SITES_FETCH_ERROR: 'Unable to load site list. Please try after sometime.',
  USER_ASSIGN_ROLE_SUCCESS: 'User role updated successfully.',
  USER_QUICKSIGHT_SUCCESS: 'User QuickSight permission updated successfully.',
  USER_ASSIGN_ROLE_ERROR: 'Unable to assign user role. Please try after sometime.',
  USER_QUICKSIGHT_ERROR: 'Unable to assign user QuickSight permission. Please try after sometime.',
  SITE_USERS_FETCH_ERROR: 'Unable to load site users. Please try after sometime.',
  NO_CHW_AVAILABLE: 'No CHW users available.',
  SITE_SUMMARY_FETCH_ERROR: 'Unable to load site summary Details. Please try after sometime.',
  UNAUTHORIZED_ACCESS_MESSAGE: `You don't have permission to perform this operation.`,
  DOWNLOAD_FAIL_MSG: 'Unable to download the file. Please try after sometime.',
  DOWNLOAD_NOT_READY: 'Please try after sometime.',
  URL_NOT_FOUND: 'NotFound',
  REPORT_VIEW_FAIL: 'Unable to view the file. Please try after sometime.',
  REPORT_DOWNLOAD_SUCCESS: 'Report downloaded successfully',
  PROFILE_MERGE_SUCCESS: 'Profiles 1 and 2 have been merged successfully.',
  PROFILE_UPDATE_UNSURE_STATUS_SUCCESS: 'Profile has been marked unsure.',
  PROFILE_UPDATE_DIFFERENT_STATUS_SUCCESS: 'Profile has been marked different.',
  REPORT_NOT_FOUND: 'Report not found.',
  REPORT_NOT_FOUND_DATE: 'Report not found on selected date.',
  REPORT_NOT_FOUND_MONTH: 'Report not found on selected month.',
  GENDER: [
    { name: 'Male', label: 'Male' },
    { name: 'Female', label: 'Female' }
  ],
  YEAR_MONTH_DATE_FORMAT: 'YYYY-MM-DD',
  DATE_MONTH_YEAR_FORMAT: 'DD/MM/YYYY',
  DATE_PICKER_FORMAT: 'dd/MM/yyyy',
  MIN_DATE: '2017-01-01',
  TRUE: 'true',
  FALSE: 'false',
  NUMBER: {
    ZERO: 0,
    ONE: 1,
    TWO: 2,
    TWELVE: 12
  },
  SITE_TENANT_ID: 'sti',
  SITE_ID: 'si',
  CHW_USER_ID: 'chwid',
  MANAGE_USER_ROLES_ACCESS: ['REPORT_ADMIN'],
  QUICKSIGHT_DASHBOARD: {
    SPICE: 'Spice Quicksight Dashboard',
    SPICE_ENGAGE: 'Spice-Engage Quicksight Dashboard'
  },
  SONARQUEBE_PASSWORD: 'admin1',
  DONE: 'Done',
  KENYA: 'kenya',
  KHIS_LAST_DATE_TO_SUBMIT: 15,
  SEND_TO_KHIS_ROLES_ACCESS: ['FACILITY_REPORT_ADMIN'],
  REPORT_PENDING: 'Report Pending',
  SEND_TO_KHIS: 'Send to KHIS',
  CANNOT_SEND_TO_KHIS: `Report can't be sent for previous months`,
  // error msg
  ASSESSMENT_LIST_FETCH_ERROR: 'Unable to load assessment list. Please try after sometime.',
  ENROLLED_PATIENTS_FETCH_ERROR: 'Unable to load enrolled patients. Please try after sometime.',
  SCREENED_PATIENTS_FETCH_ERROR: 'Unable to load screened patients. Please try after sometime.',
  USER_PERFORMANCE_FETCH_ERROR: 'Unable to load User Performance List. Please try after sometime.',
  MOH_REPORT_STATUS_ERROR: 'Unable to fetch report status. Please try after sometime.'
};

export const ROLE_LABELS = {
  [APPCONSTANTS.ROLES.REPORT_ADMIN]: 'Report Admin',
  [APPCONSTANTS.ROLES.FACILITY_REPORT_ADMIN]: 'Facility Report Admin',
  [APPCONSTANTS.ROLES.HF4_REPORT_USER]: 'HF4 Report User'
};

export const colorToCellStyleMap: { [key: string]: number } = {
  C8C8C8: 3,
  F1CEEE: 1
};

export const REPORT_STYLES = {
  HF_REPORT: {
    '1': {
      font: { bold: true },
      align: 'center',
      valign: 'middle',
      bgcolor: '#F1CEEE',
      border: {
        top: ['thin', '#000000'],
        right: ['thin', '#000000'],
        bottom: ['thin', '#000000'],
        left: ['thin', '#000000']
      }
    },
    '2': {
      textwrap: true,
      border: {
        top: ['thin', '#000000'],
        right: ['thin', '#000000'],
        bottom: ['thin', '#000000'],
        left: ['thin', '#000000']
      }
    },
    '3': {
      bgcolor: '#C8C8C8',
      border: {
        top: ['thin', '#000000'],
        right: ['thin', '#000000'],
        bottom: ['thin', '#000000'],
        left: ['thin', '#000000']
      }
    }
  },
  MOH_REPORT: {
    '1': {
      font: { bold: true },
      align: 'center',
      valign: 'middle'
    },
    '2': {
      font: { bold: true }
    },
    '3': {
      align: 'right',
      valign: 'right'
    },
    '4': {
      bgcolor: '#D3D3D3'
    }
  }
};

export const APP_TYPE = {
  COMMUNITY: 'COMMUNITY',
  NON_COMMUNITY: 'NON_COMMUNITY'
};

export const rolesGroup = {
  SPICE: 'SPICE',
  REPORTS: 'REPORTS',
  INSIGHTS: 'INSIGHTS'
};

export const suiteAccess = {
  mob: 'mob',
  admin: 'admin',
  cfr: 'cfr'
};

export const RED_RISK_USER = 'RED_RISK_USER';

export const APP_TYPE_NAME = 'appTypes';

export const USER_PERFORMANCE_ROLES = [
  {
    name: 'NURSE',
    displayName: 'Nurse'
  },
  {
    name: 'HEALTH_SCREENER',
    displayName: 'Health Screener'
  },
  {
    name: 'COMMUNITY_HEALTH_ASSISTANT',
    displayName: 'Community Health Assistant'
  },
  {
    name: 'HRIO',
    displayName: 'HRIO'
  },
  {
    name: 'NUTRITIONIST',
    displayName: 'Nutritionist'
  },
  {
    name: 'LAB_TECHNICIAN',
    displayName: 'Lab Technician'
  },
  {
    name: 'COMMUNITY_HEALTH_PROMOTER',
    displayName: 'Community Health Promoter'
  },
  {
    name: 'PROVIDER',
    displayName: 'Provider'
  },
  {
    name: 'COUNSELOR',
    displayName: 'Counselor'
  },
  {
    name: 'HEALTH_COACH',
    displayName: 'Health Coach'
  },
  {
    name: 'PHYSICIAN_PRESCRIBER',
    displayName: 'Physician Prescriber'
  },
  {
    name: 'PHARMACIST',
    displayName: 'Pharmacist'
  }
];

export default APPCONSTANTS;
