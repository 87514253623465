import APPCONSTANTS from '../../constants/appConstants';
import sessionStorageServices from '../../global/sessionStorageServices';
import * as USERTYPES from './actionTypes';

import { UserActions, IUserState, IUser } from './types';

const userInitialStateGetter = (): IUser => ({
  email: '',
  firstName: '',
  lastName: '',
  userId: '',
  role: APPCONSTANTS.ROLES.REPORT_ADMIN,
  appTypes: [],
  roleDetail: {},
  isHF4User: false,
  reportPrivileges: [],
  tenantId: '',
  formDataId: '',
  country: {},
  suiteAccess: [],
  assignedFacility: {}
});

// This should be function instead of object,
// so that the isLoggedIn will be recomputed when RESET_STATE action is dispatched

const initialStateGetter: () => IUserState = () => ({
  defaultRole: [],
  isLoggedIn: false,
  loggingIn: false,
  loggingOut: false,
  user: userInitialStateGetter(),
  chwUserList: [],
  chwUserListLoading: false,
  error: null,
  loading: false,
  initializing: false,
  isPasswordSet: false,
  email: '',
  timezoneList: [],
  errorMessage: '',
  countryName: sessionStorageServices.getItem(APPCONSTANTS.COUNTRY_NAME) || '',
  username: '',
  isKenya: false
});

const userReducer = (state = initialStateGetter(), action: UserActions): IUserState => {
  switch (action.type) {
    case USERTYPES.LOGOUT_REQUEST:
      return {
        ...state,
        loggingOut: true
      };
    case USERTYPES.LOGOUT_SUCCESS:
    case USERTYPES.LOGOUT_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        loggingOut: false
      };
    case USERTYPES.FETCH_CHW_USER_LIST_REQUEST:
      return {
        ...state,
        chwUserListLoading: true
      };
    case USERTYPES.FETCH_CHW_USER_LIST_SUCCESS:
      return {
        ...state,
        chwUserList: action.payload,
        chwUserListLoading: false
      };
    case USERTYPES.FETCH_CHW_USER_LIST_FAILURE:
      return {
        ...state,
        chwUserListLoading: false
      };
    case USERTYPES.FETCH_TIMEZONE_LIST_REQUEST:
    case USERTYPES.FETCH_ROLES_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case USERTYPES.FETCH_TIMEZONE_LIST_SUCCESS:
      return {
        ...state,
        timezoneList: action.payload,
        loading: false
      };
    case USERTYPES.FETCH_TIMEZONE_LIST_FAILURE:
    case USERTYPES.FETCH_ROLES_LIST_SUCCESS:
    case USERTYPES.FETCH_ROLES_LIST_FAILURE:
      return {
        ...state,
        loading: false
      };
    case USERTYPES.FETCH_LOGGED_IN_USER_REQUEST:
      return {
        ...state,
        initializing: true
      };
    case USERTYPES.FETCH_LOGGED_IN_USER_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        initializing: false
      };
    case USERTYPES.FETCH_LOGGED_IN_USER_SUCCESS:
      return {
        ...state,
        initializing: false,
        isLoggedIn: true,
        user: action.payload
      };
    case USERTYPES.SESSION_TIMEDOUT:
      sessionStorageServices.clearAllItem();
      return {
        ...state,
        isLoggedIn: false,
        errorMessage: action.message
      };
    case USERTYPES.FETCH_COUNTRY_NAME:
      return {
        ...state,
        countryName: sessionStorageServices.getItem(APPCONSTANTS.COUNTRY_NAME) || ''
      };
    case USERTYPES.CLEAR_COUNTRY_NAME:
      return {
        ...state,
        countryName: ''
      };
    case USERTYPES.CLEAR_COUNTRY_ID:
      return {
        ...state,
        user: { ...state.user, country: '' }
      };
    case USERTYPES.SET_HF4_USER_STATUS:
      return {
        ...state,
        user: { ...state.user, isHF4User: action.payload }
      };
    case USERTYPES.SET_APP_TYPE:
      return {
        ...state,
        user: {
          ...state.user,
          appTypes: action.payload
        }
      };
    case USERTYPES.SET_IS_KENYA:
      return {
        ...state,
        isKenya: action.value
      };
    case USERTYPES.SET_REPORT_PRIVILEGES:
      return {
        ...state,
        user: {
          ...state.user,
          reportPrivileges: action.payload
        }
      };
    default:
      return {
        ...state
      };
  }
};

export default userReducer;
