import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomDatePicker from '../../components/filter/CustomDatePicker';
import APPCONSTANTS from '../../constants/appConstants';
import DetailCard from '../../components/detailCard/DetailCard';
import CustomTable from '../../components/customtable/CustomTable';
import Loader from '../../components/loader/Loader';
import styles from './ReportList.module.scss';
import Modal from '../../components/modal/ModalForm';
import SpreadsheetViewer from '../../components/spreadSheetViewer/SpreadSheetViewer';
import { fetchReportList, fetchDailyReport, fileDownload } from '../../services/reportAPI';
import toastCenter from '../../utils/toastCenter';
import { formatDate } from '../../utils/reportUtil';
import 'react-datepicker/dist/react-datepicker.css';
import { firstNameSelector, lastNameSelector, roleSelector } from '../../store/user/selectors';
import { useSelector } from 'react-redux';
import { getPageLocationWithParams, trackGoogleAnalyticsEvent } from '../../utils/commonUtils';

interface IMatchParams {
  siteId: string;
  siteName: string;
}

interface IDatesParams {
  id?: number;
  date?: string;
  label?: string;
}

interface IReportLists {
  id: number;
  key: string;
}

const DailyReport = (): React.ReactElement => {
  const { siteId, siteName } = useParams<IMatchParams>();
  const [modalParams, setModalReqParams] = useState({
    loading: false,
    data: {}
  });
  const [reportParams, setReportParams] = useState<IDatesParams[]>([]);
  const [loading, setLoading] = useState(false);
  const role: any = useSelector(roleSelector);
  const firstName: string = useSelector(firstNameSelector) || '';
  const lastName: string = useSelector(lastNameSelector) || '';

  useEffect(() => {
    trackGoogleAnalyticsEvent(
      APPCONSTANTS.USER_ACTIONS.LIST_VIEW,
      APPCONSTANTS.REPORT_LIST.DAILY_REGISTER,
      getPageLocationWithParams(),
      { username: `${firstName} ${lastName}`, role: role.label }
    );
    getReportList('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getReportList = useCallback(
    async (yearMonth: string) => {
      const datesList: IDatesParams[] = [];
      try {
        setLoading(true);
        const payLoad = await fetchReportList({
          key: yearMonth,
          reportType: 'DAILY_REGISTER',
          healthFacilityId: Number(siteId),
          limit: APPCONSTANTS.NUMBER.TWELVE
        });
        payLoad.data.entityList.map((res: IReportLists) => {
          return datesList.push({
            id: res.id,
            date: res.key,
            label: res.key.replace(/\./g, '/')
          });
        });
        setLoading(false);
        setReportParams(datesList);
      } catch (error: any) {
        setReportParams(datesList);
        setLoading(false);
        toastCenter.error(APPCONSTANTS.ERROR, APPCONSTANTS.REPORT_NOT_FOUND_DATE);
      }
    },
    [siteId]
  );

  const getReportView = useCallback(
    async (report) => {
      try {
        setLoading(true);
        const {
          data: { entityList }
        } = await fetchDailyReport({
          date: report.date,
          healthFacilityId: Number(siteId)
        });
        trackGoogleAnalyticsEvent(
          APPCONSTANTS.USER_ACTIONS.REPORT_VIEW,
          APPCONSTANTS.REPORT_LIST.DAILY_REGISTER,
          getPageLocationWithParams(),
          { username: `${firstName} ${lastName}`, role: role.label }
        );
        setLoading(false);
        setModalReqParams({ loading: true, data: entityList });
      } catch (error: any) {
        setLoading(false);
        toastCenter.error(APPCONSTANTS.ERROR, APPCONSTANTS.REPORT_NOT_FOUND_DATE);
      }
    },
    [firstName, lastName, role.label, siteId]
  );

  const downloadFile = useCallback(
    async (report) => {
      try {
        setLoading(true);
        const {
          data: { entityList }
        } = await fetchDailyReport({
          date: report.date,
          healthFacilityId: Number(siteId)
        });
        trackGoogleAnalyticsEvent(
          APPCONSTANTS.USER_ACTIONS.REPORT_DOWNLOAD,
          APPCONSTANTS.REPORT_LIST.DAILY_REGISTER,
          getPageLocationWithParams(),
          { username: `${firstName} ${lastName}`, role: role.label }
        );
        setLoading(false);
        fileDownload(siteName, '_Daily_', report.date, entityList);
        toastCenter.success(APPCONSTANTS.SUCCESS, APPCONSTANTS.REPORT_DOWNLOAD_SUCCESS);
      } catch (error: any) {
        setLoading(false);
        toastCenter.error(APPCONSTANTS.ERROR, APPCONSTANTS.REPORT_NOT_FOUND_DATE);
      }
    },
    [firstName, lastName, role.label, siteId, siteName]
  );

  const viewModalRender = () => {
    return (
      <SpreadsheetViewer
        data={modalParams.data}
        isEdit={false}
        className={styles.spreadSheetContainer}
        colWidth={150}
        reportSize={{ charPixel: 10, index: [], rowHeight: 25 }}
      />
    );
  };

  const handleCancelClick = () => {
    setModalReqParams({ loading: false, data: {} });
  };

  const onDateSelection = useCallback(
    async (date: Date) => {
      const datesList: IDatesParams[] = [];
      if (date) {
        setLoading(true);
        try {
          await fetchReportList({
            key: formatDate(date, 'dot'),
            reportType: 'DAILY_REGISTER',
            healthFacilityId: Number(siteId),
            limit: APPCONSTANTS.NUMBER.TWELVE
          });
          datesList.push({
            id: 0,
            date: formatDate(date, 'dot'),
            label: formatDate(date, 'slash')
          });
          setReportParams(datesList);
          trackGoogleAnalyticsEvent(
            APPCONSTANTS.USER_ACTIONS.FILTERS.DATE_FILTER,
            APPCONSTANTS.REPORT_LIST.DAILY_REGISTER,
            getPageLocationWithParams(),
            { username: `${firstName} ${lastName}`, role: role.label }
          );
          setLoading(false);
        } catch (error: any) {
          setReportParams(datesList);
          setLoading(false);
          toastCenter.error(APPCONSTANTS.ERROR, APPCONSTANTS.REPORT_NOT_FOUND_DATE);
        }
      } else {
        getReportList('');
      }
    },
    [firstName, getReportList, lastName, role.label, siteId]
  );

  return (
    <>
      {loading && <Loader />}
      <div className='row'>
        <div className='col-auto'>
          <div className={`${styles.sideMenu} ${styles.customSidemenu}`}>
            <div className={`card-header bg-transparent ${styles.header}`}>
              <span className={styles.headerLabel}>Filter</span>
            </div>
            <br />
            <div>
              <label>Date</label>
              <CustomDatePicker dayReducer={1} onDateSelect={(date: Date) => onDateSelection(date)} />
            </div>
          </div>
        </div>
        <div className='col'>
          <DetailCard header='Daily Activity Register MOH 222'>
            <CustomTable
              rowData={reportParams}
              columnsDef={[
                {
                  id: 1,
                  name: 'label',
                  label: 'Date',
                  class: 'numeric'
                }
              ]}
              isDelete={false}
              isEdit={false}
              isView={true}
              isDownload={true}
              onRowView={(report) => getReportView(report)}
              onRowDownload={(report) => downloadFile(report)}
            />
          </DetailCard>
          <Modal
            show={modalParams.loading}
            title='Daily Activity Register MOH 222 View'
            size='modal-full'
            hasFooter={false}
            handleCancel={handleCancelClick}
            handleFormSubmit={handleCancelClick}
            render={viewModalRender}
          />
        </div>
      </div>
    </>
  );
};

export default DailyReport;
